.tad-table {
  width: 100%;
  font-size: 14px;
  tbody {
    padding-top: 10px;
  }
}

.tad-content-row > td:nth-child(odd) {
  width: 49%;
  padding: 0 20px;
  font-weight: 600;
}

.tad-content-row > td:nth-child(odd) > p {
  margin: 0;
}

.tad-content-row > td:nth-child(1) {
  text-align: right;
}

.tad-content-row > td:nth-child(2) {
  width: 2%;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.tad-connector-row {
  display: flex;
  justify-content: center;
}

.tad-connector-row > td:nth-child(2) {
  text-align: center;
}
.tad-connector-row > td:nth-child(2) > div {
  width: 0.125rem;
  height: 3.3125rem;
  display: flex;
  justify-content: space-between;
  background-color: #333;
}

.tad-connector-row1 div {
  height: 40px;
  width: 2px;
  background-color: #d3d3d3;
  // margin-left: 26px;
}

.tad-connector-row1 {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 2.7rem;
  p {
    margin-left: 2.5rem;
  }
}

.tad-icon-element {
  background-color: #e0e0e0;
  color: #333;
  font-weight: bold;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.tad-connector-row:last-child {
  display: none;
}

.tad-process-row > td:nth-child(odd) {
  width: 49%;
  padding: 0 20px;
  font-weight: 600;
}

.tad-process-row1 {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  font-weight: 700;
  margin-left: 26px;
  align-items: center;
  height: 2rem;
}

.tad-process-row > td:nth-child(odd) > p {
  margin: 0;
}

.tad-process-row > td:nth-child(1) {
  text-align: right;
}

.tad-process-row > td:nth-child(2) {
  width: 2%;
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
