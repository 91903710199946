.main-div {
  background: var(--Secondary-colors-Grey-3);
  padding: 0rem 2.5rem 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  // margin-top: 0.5rem;
  min-height: 85vh;
  // padding-top: 2rem;
}

.dashboard-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -1.5rem;
}

.date .MuiInputBase-input {
  display: none !important;
}

.date .MuiOutlinedInput-notchedOutline {
  display: contents !important;
}

.date .MuiButtonBase-root.MuiIconButton-root {
  // content: '' !important;
  // display: inline-block !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  background-image: url('../../assets/dashboard-assets/event.svg') !important;
  background-size: cover !important;
}

.date .MuiIconButton-root .MuiSvgIcon-root {
  display: none !important;
}

.paper {
  border-radius: 0.3125rem;
  border: 1px solid #c1c0c0;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

// .right-paper {
//   height: 26.125rem;
//   flex-shrink: 0;
//   border-radius: 5px;
//   border: 1px #c1c0c0;
//   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
//   margin-bottom: 1.88rem;
// }

.heading {
  font-weight: 600;
  font-size: 1.625rem;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.tab {
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #4e4f50 !important;
  text-transform: none !important;
  &.Mui-selected {
    color: #333 !important;
  }
  &:first-child {
    margin: 0px 20px 6px 0px;
    padding-left: 0 !important;
  }
  .MuiTabs-indicator {
    background-color: #333 !important;
  }
}

.selected-tab {
  color: #333 !important;
}

.tab .MuiTabs-indicator {
  background-color: #333 !important;
}

.paper .MuiButtonBase-root-MuiTab-root {
  padding: 0;
}

.button .MuiTabs-flexContainer {
  gap: 43px;
}

.barchart .recharts-cartesian-axis-tick-line {
  display: none;
}

.barchart .recharts-cartesian-grid-vertical {
  display: none;
}
.barchart .recharts-cartesian-axis-line {
  display: none;
}

Select {
  border-radius: 0.3125rem;
  border: 1px solid var(--Secondary-Grey-2, #e7e7e7);
  background: var(--Secondary-colors-White, #fff);
}

.bar .recharts-cartesian-axis-tick-line {
  display: none;
}

.bar .recharts-cartesian-grid-vertical {
  display: none;
}
.bar .recharts-cartesian-axis-line {
  display: none;
}

.bar .recharts-legend-wrapper {
  display: none;
}

.piechart .recharts-wrapper {
  position: sticky;
  width: 500px;
  margin-left: 50px;
}

.new-dashboard {
  display: flex;
  .left-section {
    width: 66.7%;
  }
  .right-section {
    width: 32.36%;
  }

  .qc-table {
    overflow: scroll;
    max-height: 40vh;
  }
  .qc-table::-webkit-scrollbar {
    width: 8px;
  }

  .qc-table::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .qc-table::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  .qc-table::-webkit-scrollbar-thumb:horizontal {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .active-interviews {
    max-height: 50vh;
    overflow-y: scroll;
  }

  .active-interviews::-webkit-scrollbar {
    width: 8px;
  }
  .active-interviews::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .active-interviews:hover {
    &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }
  }
  .interview-info {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    > div {
      display: flex;
      margin-bottom: 5px;
      > div {
        width: 50%;
        > span:nth-of-type(1) {
          font-size: 12px;
          font-weight: 400;
        }
        > span:nth-of-type(2) {
          font-size: 12px;
          font-weight: 700;
        }
      }
    }
  }
}

.new-job-requisition {
  display: flex;
  align-items: center;
  background-image: url('../../assets/sjd/abstract-plexus-background-with-connected-lines-dots-plexus-geometric-effect-digital-data-visual_230610-1011\ 1.png');
  background-position: right center;
  background-repeat: no-repeat;
  border-radius: 0.3125rem;
  border: 1px #c1c0c0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  justify-content: space-between;
}

.inputFeilds {
  border-radius: 0.3125rem !important;
  border: 1px solid var(--Secondary-colors-Grey, #868686) !important;
  background: var(--Secondary-colors-White, #fff) !important;
  .MuiInputBase-root {
    height: var(--Font-size-h2, 2.5rem) !important;
  }
}

.feildHeader {
  color: var(--Secondary-Grey-1, var(--Secondary-colors-Grey, #868686));
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.ql-snow .ql-picker.ql-header {
  display: none;
}

.ql-toolbar.ql-snow .ql-formats {
  .ql-underline {
    display: none;
  }
  .ql-clean {
    display: none;
  }
}

.datepickers .MuiInputBase-root-MuiOutlinedInput-root {
  height: var(--Font-size-h2, 2.5rem) !important;
  padding: 9px !important;
}

.visaType .MuiOutlinedInput-notchedOutline {
  border-color: rgb(0 0 0 / 6%) !important;
}

.visaType
  .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: grey !important;
}

.salaryType .MuiInputBase-root-MuiOutlinedInput-root {
  height: var(--Font-size-h2, 2.5rem) !important;
  border-radius: 0 !important;
  border-top: 1px solid var(--Secondary-colors-Grey, #868686) !important;
  border-bottom: 1px solid var(--Secondary-colors-Grey, #868686) !important;
}

.salaryContact .MuiInputBase-root-MuiOutlinedInput-root {
  height: var(--Font-size-h2, 2.5rem) !important;
  border-radius: 0rem 0.31rem 0.31rem 0rem !important;
  border: 1px solid var(--Secondary-colors-Grey, #868686) !important;
  .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: rgb(0 0 0 / 70%) !important;
  }
}

.first-div-header {
  padding: 0rem 1rem;
  height: 61px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c1c0c0;
}

.first-div-header-left {
  display: flex;
  align-items: center;
  img {
    margin-left: 20px;
    margin-right: 10px;
    height: 24px;
    @media (max-width: 1537px) {
      height: 20px !important;
    }
  }
  span {
    font-size: small;
    font-weight: 600;
    @media (max-width: 1537px) {
      font-size: small;
    }
  }
  .icon-btn {
    @media (max-width: 1537px) {
      font-size: small;
    }
  }
}

.first-div-header-right {
  display: flex;
  align-items: center;
  img {
    margin-left: 20px;
    margin-right: 10px;
    height: 24px;
    @media (max-width: 1537px) {
      height: 20px !important;
    }
  }
  span {
    font-size: small;
    font-weight: 600;
    @media (max-width: 1537px) {
      font-size: small !important;
    }
  }
}

.interview-div-container {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem 1rem;
  gap: 1.25rem;
  height: 18.4rem;
  display: flex;
  flex-direction: column;
}

.interview-divider {
  margin-right: 8px !important;
  border-color: #c1c0c0 !important;
  width: 0.125rem !important;
}

.raido-color .MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #323232 !important;
}

:root {
  --Primary-Blue-500: #1976d2;
  --Font-size-h3: 2.0625rem;
}

.dashboard-title {
  color: var(--Primary-Dark-grey-500, #333) !important;
  font-family: 'Josefin Slab' !important;
  font-size: 2rem !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 1.5625rem !important;
  text-transform: uppercase !important;
}

.dashboard-heading {
  color: var(--Primary-Dark-grey-500, #333) !important;
  text-align: center;
  font-family: Manrope !important;
  font-size: 2rem;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.notification-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  span {
    color: #333;
  }
}
.notification-field-label {
  color: #999 !important;
  font-family: Roboto !important;
  font-size: small !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.notification-container {
  display: flex !important;
  margin-top: 18px !important;
  justify-content: space-between !important;
}
.notification-header {
  display: flex !important;
  justify-content: space-between !important;
}
.notification-chip {
  border-radius: 5px !important;
  background-color: var(--Primary-Blue-100, #b8d5f1) !important;
  color: #333 !important;
  font-weight: 400 !important;
}
.notification-Avatar {
  height: 30px !important;
  width: 30px !important;
  cursor: pointer;
}
.notification-Avatar-text {
  font-size: small !important;
  color: #fff !important;
}
.notification-button-group {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
.left-button {
  height: 2rem !important;
  font-weight: normal !important;
  font-size: small !important;
  color: #333 !important;
  border: 1px solid #1976d2 !important;
  background-color: #fff !important;
  text-transform: none !important;
}
.right-button {
  font-weight: normal !important;
  height: 2rem !important;
  font-size: small !important;
  color: #fff !important;
  background: #1976d2 !important;
  text-transform: none !important;
}
.notification-date .MuiInputBase-input {
  display: none !important; /* Hide the text input field */
}

.notification-date .MuiOutlinedInput-notchedOutline {
  border: none !important; /* Remove border instead of using display: contents */
}

.notification-date .MuiIconButton-root {
  width: 1.2rem !important;
  height: 1.2rem !important;
  background: url('../../assets/dashboard-assets/Vector.svg') center/70% no-repeat !important;
}

.notification-date .MuiIconButton-root .MuiSvgIcon-root {
  display: none !important;
}

.no-records-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
}
